/* eslint-disable no-unused-vars */
import React, { useState, useEffect, memo, useRef } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  HelpOutline as HelpOutlineIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  isBefore,
  isWithinInterval,
  addMonths,
  differenceInCalendarDays,
  parseISO,
  addDays,
  isEqual,
  startOfDay,
} from 'date-fns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import EventBus from '../../common/EventBus';
import {
  createOrUpdateLicenseEvent,
  getLicenseEventsAndDelete,
} from '../../common/utils/DateUtils.js';
import {
  getUpdatedLicenseEmailCases,
  degreesAndLicenseOptions,
  licensedInOtherStatesOptions,
} from '../../common/utils/ProviderConfigUtils.js';
import { hasCredentialingAccess } from '../../common/utils/RoleUtils';

import styles from '../../styles/ProviderConfig.module.css';
import { setLoading, clearLoading } from '../../actions/loading';
import {
  driveService,
  userService,
  liabilityService,
  providerService,
} from '../../services';
import {
  LiabilityComponent,
  CvComponent,
  DiplomaComponent,
} from '../DriveComponent';
import { RenewMdDialog, OutOfStateDialog, PsyPactDialog } from '../Dialogs';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const sectionStyle = {
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  backgroundColor: '#fafafa',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Utility function to calculate days until expiration
const daysUntilExpiration = (expirationDate) => {
  const today = new Date();
  const expiryDate = parseISO(expirationDate);
  return differenceInCalendarDays(expiryDate, today);
};

// Example usage within a component to decide whether to display the renewal section
const shouldShowRenewalOptions = (expirationDate, type) => {
  const daysUntilExpiry = daysUntilExpiration(expirationDate);
  const threshold = type === 'liability' ? 33 : 48; // 33 days for liability, 48 for others
  return daysUntilExpiry <= threshold;
};

const getUpdatedCredentialingTaskCreated = (
  credentialing_tasks_created,
  changesMadeForEvents,
  selectedUserDetail
) => {
  const credentialingTasksCreatedCopy = { ...credentialing_tasks_created };

  if (changesMadeForEvents.Maryland === true) {
    delete credentialingTasksCreatedCopy[`mdLicense-${selectedUserDetail.id}`];
  }
  return credentialingTasksCreatedCopy;
};

const checkAccordionDegreeLicenseCompletion = (degrees) => {
  return degrees?.length > 0;
};

const ProviderProfessionalInfo = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { /* selectedUser, */ selectedUserDetail } = props;
  const [dob, setDob] = React.useState();
  const [licensedInOtherStates, setLicensedInOtherStates] = React.useState([]);
  const [isPsyPactMember, setIsPsyPactMember] = useState('no'); // 'yes' or 'no'
  const [degreesAndLicense, setDegreesAndLicense] = useState(
    props?.selectedUserDetail?.degrees_and_license ?? []
  );
  const [showPsyPactMember, setShowPsyPactMember] = useState(false);
  const [psyPactVerification, setPsyPactVerification] = useState('');
  const [memberJoiningDate, setMemberJoiningDate] = useState('');
  const [memberExpiryDate, setMemberExpiryDate] = useState('');
  const [psyPactMobilityNumber, setPsyPactMobilityNumber] = useState('');
  const [mdLicenseNumber, setMdLicenseNumber] = useState('');
  const [mdEffectiveDate, setMdEffectiveDate] = useState('');
  const [mdExpirationDate, setMdExpirationDate] = useState('');
  // The below state is for storing tempExpiration Date
  const [mdVerificationLink, setMdVerificationLink] = useState('');
  const [waitingOnMdLicense, setWaitingOnMdLicense] = useState(false);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [supervisorName, setSupervisorName] = React.useState('');
  const [supervisorEmail, setSupervisorEmail] = React.useState('');
  const [providerName, setProviderName] = React.useState('');
  const [showSupervisorNameField, setShowSupervisorNameField] = useState(false);
  const [showSupervisorEmailField, setShowSupervisorEmailField] =
    useState(false);
  const [otherStatesLicensed, setOtherStatesLicensed] = useState('no'); // Default to 'no'
  const [showStatesDropdown, setShowStatesDropdown] = useState(false); // Controls visibility of the dropdown
  const [cvFiles, setCvFiles] = useState([]);
  const [diplomaFiles, setDiplomaFiles] = useState([]);
  const [liabilityFiles, setLiabilityFiles] = useState([]);
  const [stateDetails, setStateDetails] = useState({});
  const [activeAccordions, setActiveAccordions] = useState(
    new Set([props.accordionToOpen])
  );

  const [policyPurchased, setPolicyPurchased] = useState(false);
  const [policyAutoRenewal, setPolicyAutoRenewal] = useState(false);
  const [contactedInsurance, setContactedInsurance] = useState(false);
  const [contactedMDBoard, setContactedMDBoard] = useState(false);
  const [contactAttemptCounter, setContactAttemptCounter] = useState(0);
  const [contactedPsyPact, setContactedPsyPact] = useState(false);
  const [notRenewingPsyPact, setNotRenewingPsyPact] = useState(false);
  // The below state is responsible for tracking the changesMade for the events.
  const [changesMadeForEvents, setChangesMadeForEvents] = useState({});
  const [activeLiabilityType, setActiveLiabilityType] = useState('current'); // 'current' or 'expired'
  const [renewalOptions, setRenewalOptions] = useState({
    current: {
      policyPurchased: false,
      policyAutoRenewal: false,
      contactedInsurance: false,
    },
    expired: {
      policyPurchased: false,
      policyAutoRenewal: false,
      contactedInsurance: false,
    },
  });

  // for UI/license branch
  const [openMDLicModal, setOpenMDLicModal] = useState(false);
  const [expirationMDDateEmpty, setExpirationMDDateEmpty] = useState(true);
  const [currentMDLicenseStep, setCurrentMDLicenseStep] = useState(1);
  const [linkMDLicClicked, setLinkMDLicClicked] = useState(false);

  const [openPsyPactModal, setOpenPsyPactModal] = useState(false);
  const [expirationPsyPactDateEmpty, setExpirationPsyPactDateEmpty] =
    useState(true);
  const [currentPsyPactStep, setCurrentPsyPactStep] = useState(1);
  const [linkPsyPactLicClicked, setLinkPsyPactLicClicked] = useState(false);

  const [openActiveStateModal, setOpenActiveStateModal] = useState(false);
  const [activeState, setActiveState] = useState(null);
  const [expirationActiveStateDateEmpty, setExpirationActiveStateDateEmpty] =
    useState(true);
  const [currentActiveStateStep, setCurrentActiveStateStep] = useState(1);
  const [linkActiveStateClicked, setLinkActiveStateClicked] = useState(false);

  //Accordion states for completed fields
  const [
    isAccordionDegreeLicenseCompleted,
    setIsAccordionDegreeLicenseCompleted,
  ] = useState(false);
  const [isAccordionSupervisionCompleted, setIsAccordionSupervisionCompleted] =
    useState(false);
  const [isAccordionMdLicenseCompleted, setIsAccordionMdLicenseCompleted] =
    useState(false);
  const [isAccordionPsyPactCompleted, setIsAccordionPsyPactCompleted] =
    useState(false);
  const [
    isAccordionOutStateLicenseCompleted,
    setIsAccordionOutStateLicenseCompleted,
  ] = useState(false);
  const [isAccordionCVCompleted, setIsAccordionCVCompleted] = useState(false);
  const [isAccordionDiplomaCompleted, setIsAccordionDiplomaCompleted] =
    useState(false);
  const [
    isAccordionLiabilityInsuranceCompleted,
    setIsAccordionLiabilityInsuranceCompleted,
  ] = useState(false);
  const [triggerAPICall, setTriggerAPICall] = useState(false);

  // The below are the useRefs for making user to scroll to the view
  const psypactRef = useRef(null);
  const outOfStateRef = useRef(null);
  const maryLandStateLicenseRef = useRef(null);

  const dispatch = useDispatch();

  const questionText =
    degreesAndLicense.includes('Doctoral Level') && isPsyPactMember === 'yes'
      ? 'In addition to the PsyPact, are you licensed in other states other than Maryland?'
      : 'Are you licensed in other states outside of Maryland?';

  // Function to get the expiration date color
  const getExpirationDateColor = (expirationDate) => {
    const today = new Date();
    const expDate = new Date(expirationDate);

    if (isBefore(expDate, today)) {
      return 'red'; // Expired
    }

    if (isWithinInterval(expDate, { start: today, end: addMonths(today, 1) })) {
      return 'orange'; // Within a month
    }

    return 'inherit'; // Default color
  };

  const checkAccordionSupervisionCompletion = (
    supervisorName,
    supervisorEmail
  ) => {
    return supervisorName?.length > 0 && supervisorEmail?.length > 0;
  };
  const checkAccordionMdLicenseCompletion = (
    mdLicenseNumber,
    waitingOnMdLicense,
    mdEffectiveDate,
    mdExpirationDate,
    mdVerificationLink
  ) => {
    if (waitingOnMdLicense) {
      return true;
    } else {
      return (
        mdLicenseNumber !== '' &&
        mdEffectiveDate !== '' &&
        mdExpirationDate !== '' &&
        mdVerificationLink !== ''
      );
    }
  };

  const checkAccordionPsyPactCompletion = (
    isPsyPactMember,
    psyPactMobilityNumber,
    memberJoiningDate,
    memberExpiryDate,
    psyPactVerification
  ) => {
    if (isPsyPactMember === 'no') {
      return true;
    } else if (isPsyPactMember === 'yes') {
      // Check if all required fields are filled out
      return (
        psyPactMobilityNumber !== '' &&
        memberJoiningDate !== '' &&
        memberExpiryDate !== '' &&
        psyPactVerification !== ''
      );
    }
    return false;
  };

  const checkAccordionOutStateLicenseCompletion = (
    stateDetails,
    otherStatesLicensed
  ) => {
    if (otherStatesLicensed === 'yes') {
      const validStates = Object.values(stateDetails).filter(
        (detail) =>
          detail.licenseNumber !== '' &&
          detail.effectiveDate !== '' &&
          detail.expirationDate !== '' &&
          detail.verificationLink !== ''
      );

      const isCompleted = validStates.length > 0;
      return isCompleted;
    } else if (otherStatesLicensed === 'no') {
      return true; // Mark as completed if "No" is selected
    }
    return false;
  };

  const checkAccordionCVCompletion = (cvFiles) => {
    return cvFiles.length > 0;
  };

  const checkAccordionDiplomaCompletion = (diplomaFiles) => {
    return diplomaFiles.length > 0;
  };

  const checkAccordionLiabilityCompletion = (files) => {
    return files.some((file) => file.type === 'current');
  };

  const { user: currentUser } = useSelector((state) => state.auth);

  const getCurrentLiabilityFile = () => {
    return liabilityFiles.find((file) => file.type === activeLiabilityType);
  };

  const handlePolicyPurchasedChange = async (event, type) => {
    const { checked } = event.target;
    const currentLiabilityFile = liabilityFiles.find(
      (file) => file.type === type
    );

    if (!currentLiabilityFile) {
      enqueueSnackbar('No liability file found for the selected type.', {
        variant: 'error',
        timeout: 3000,
      });
      return;
    }

    // Access the specific renewal options for the given type
    const { policyAutoRenewal, contactedInsurance } = renewalOptions[type];

    if (!policyAutoRenewal && !contactedInsurance) {
      // Update the state for the specific type
      setRenewalOptions((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          policyPurchased: checked,
        },
      }));

      try {
        await liabilityService.updateLiabilityRenewalOptions(
          {
            policyPurchased: checked,
            policyAutoRenewal: policyAutoRenewal,
            contactedInsurance: contactedInsurance,
            contactedInsuranceAttemptCount: 0,
          },
          currentLiabilityFile.file_id
        );

        enqueueSnackbar('Renewal Options updated successfully!', {
          variant: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.error('Error updating renewal options:', error);
        enqueueSnackbar('Failed to update renewal options.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handlePolicyAutoRenewalChange = async (event, type) => {
    const { checked } = event.target;
    const currentLiabilityFile = liabilityFiles.find(
      (file) => file.type === type
    );
    if (!currentLiabilityFile) {
      enqueueSnackbar('No liability file found for the selected type.', {
        variant: 'error',
        timeout: 3000,
      });
      return;
    }

    // Access the specific renewal options for the given type
    const { contactedInsurance, policyPurchased } = renewalOptions[type];

    if (!contactedInsurance && !policyPurchased) {
      // Update the state for the specific type
      setRenewalOptions((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          policyAutoRenewal: checked,
        },
      }));

      try {
        await liabilityService.updateLiabilityRenewalOptions(
          {
            policyPurchased: policyPurchased,
            policyAutoRenewal: checked,
            contactedInsurance: contactedInsurance,
            contactedInsuranceAttemptCount: 0,
          },
          currentLiabilityFile.file_id
        );

        enqueueSnackbar('Renewal Options updated successfully!', {
          variant: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.error('Error updating renewal options:', error);
        enqueueSnackbar('Failed to update renewal options.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleContactedInsuranceChange = async (event, type) => {
    const { checked } = event.target;
    const currentLiabilityFile = liabilityFiles.find(
      (file) => file.type === type
    );
    if (!currentLiabilityFile) {
      enqueueSnackbar('No liability file found for the selected type.', {
        variant: 'error',
        timeout: 3000,
      });
      return;
    }

    // Access the specific renewal options for the given type
    const { policyAutoRenewal, policyPurchased } = renewalOptions[type];

    if (!policyAutoRenewal && !policyPurchased) {
      // Update the state for the specific type
      setRenewalOptions((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          contactedInsurance: checked,
        },
      }));

      try {
        await liabilityService.updateLiabilityRenewalOptions(
          {
            policyPurchased: policyPurchased,
            policyAutoRenewal: policyAutoRenewal,
            contactedInsurance: checked,
            contactedInsuranceAttemptCount: 0,
          },
          currentLiabilityFile.file_id
        );

        enqueueSnackbar('Renewal Options updated successfully!', {
          variant: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.error('Error updating renewal options:', error);
        enqueueSnackbar('Failed to update renewal options.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleContactedMDBoardChange = (event) => {
    setContactedMDBoard(event.target.checked);
    setContactAttemptCounter(0);
  };

  const handleOutOfStateContactedBoard = (e, state) => {
    if (!stateDetails[state].noPlanToRenew) {
      // we cannot have outOfState
      setStateDetails((prevDetails) => ({
        ...prevDetails,
        [state]: {
          ...prevDetails[state],
          contactedMDBoard: e.target.checked ? true : false,
          contactAttemptCounter: 0,
        },
      }));
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleOutOfStateNoPlanToRenew = (e, state) => {
    if (!stateDetails[state].contactedMDBoard) {
      setStateDetails((prevDetails) => ({
        ...prevDetails,
        [state]: {
          ...prevDetails[state],
          noPlanToRenew: e.target.checked ? true : false,
        },
      }));
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleContactedPsyPactChange = (event) => {
    if (!notRenewingPsyPact) {
      setContactedPsyPact(event.target.checked);
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleNotRenewingPsyPactChange = (event) => {
    if (!contactedPsyPact) {
      setNotRenewingPsyPact(event.target.checked);
    } else {
      enqueueSnackbar('Either one of the checkbox can be selected', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  useEffect(() => {
    if (props?.accordionToOpen?.includes('MDLicense')) {
      if (maryLandStateLicenseRef?.current) {
        maryLandStateLicenseRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      setOpenMDLicModal(true);
    } else if (props?.accordionToOpen?.includes('PsyPact')) {
      if (psypactRef?.current) {
        psypactRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      setOpenPsyPactModal(true);
    } else if (props?.accordionToOpen?.includes('outOfStateLicensure')) {
      if (outOfStateRef?.current) {
        outOfStateRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      setOpenActiveStateModal(true);
    }
  }, [props.accordionToOpen]);

  useEffect(() => {
    const isCompleted =
      checkAccordionDegreeLicenseCompletion(degreesAndLicense);
    setIsAccordionDegreeLicenseCompleted(isCompleted);
  }, [degreesAndLicense]);

  useEffect(() => {
    const isCompleted = checkAccordionSupervisionCompletion(
      supervisorName,
      supervisorEmail
    );
    setIsAccordionSupervisionCompleted(isCompleted);
  }, [supervisorName, supervisorEmail]); // Depend on supervisor name and email states

  useEffect(() => {
    const isCompleted = checkAccordionMdLicenseCompletion(
      mdLicenseNumber,
      waitingOnMdLicense
    );
    setIsAccordionMdLicenseCompleted(isCompleted);
  }, [mdLicenseNumber, waitingOnMdLicense]); // Depend on both states

  useEffect(() => {
    const isCompleted = checkAccordionMdLicenseCompletion(
      mdLicenseNumber,
      waitingOnMdLicense,
      mdEffectiveDate,
      mdExpirationDate,
      mdVerificationLink
    );
    setIsAccordionMdLicenseCompleted(isCompleted);
  }, [
    mdLicenseNumber,
    waitingOnMdLicense,
    mdEffectiveDate,
    mdExpirationDate,
    mdVerificationLink,
  ]);

  useEffect(() => {
    const isCompleted = checkAccordionPsyPactCompletion(
      isPsyPactMember,
      psyPactMobilityNumber,
      memberJoiningDate,
      memberExpiryDate,
      psyPactVerification
    );
    setIsAccordionPsyPactCompleted(isCompleted);
  }, [
    isPsyPactMember,
    psyPactMobilityNumber,
    memberJoiningDate,
    memberExpiryDate,
    psyPactVerification,
  ]);

  useEffect(() => {
    const isCompleted = checkAccordionOutStateLicenseCompletion(
      stateDetails,
      otherStatesLicensed
    );
    setIsAccordionOutStateLicenseCompleted(isCompleted);
  }, [stateDetails, otherStatesLicensed]);

  useEffect(() => {
    const isCompleted = checkAccordionCVCompletion(cvFiles);
    setIsAccordionCVCompleted(isCompleted);
  }, [cvFiles]);

  useEffect(() => {
    if (selectedUserDetail?.id) {
      fetchData('cv');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetail, triggerAPICall]);

  useEffect(() => {
    if (selectedUserDetail?.id) {
      fetchData('diploma');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetail, triggerAPICall]);

  useEffect(() => {
    const isCompleted = checkAccordionDiplomaCompletion(diplomaFiles);
    setIsAccordionDiplomaCompleted(isCompleted);
  }, [diplomaFiles]);

  useEffect(() => {
    fetchData('liability');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetail, triggerAPICall]);

  useEffect(() => {
    const isCompleted = checkAccordionLiabilityCompletion(liabilityFiles);
    setIsAccordionLiabilityInsuranceCompleted(isCompleted);
  }, [liabilityFiles]);

  useEffect(() => {
    if (waitingOnMdLicense) {
      setMdLicenseNumber();
      setMdEffectiveDate();
      setMdExpirationDate();
      setMdLicenseNumber();
    }
  }, [waitingOnMdLicense]);

  useEffect(() => {
    const date = moment(
      props.selectedUserDetail?.date_of_birth?.split('T')[0] ?? ''
    ).format('YYYY-MM-DD');
    setDob(date);
    const licensedStates =
      props.selectedUserDetail?.licensed_in_other_states
        ?.split(',')
        .map((obj) => {
          return obj.trim();
        })
        .filter((state) => state) ?? []; // Filter out any empty strings
    setLicensedInOtherStates(licensedStates);

    setDegreesAndLicense(props?.selectedUserDetail?.degrees_and_license ?? []);
    setSupervisorName(props.selectedUserDetail?.supervisor_name);
    setSupervisorEmail(props.selectedUserDetail?.supervisor_Email ?? '');
    setProviderName(
      props.selectedUserDetail?.provider_name ??
        props.selectedUserDetail?.username ??
        ''
    );
    setMdLicenseNumber(
      props?.selectedUserDetail?.marylandLicenseDetails?.licenseNumber
    ); // setting maryland license number here
    setMdEffectiveDate(
      props?.selectedUserDetail?.marylandLicenseDetails?.effectiveDate
    );
    setMdExpirationDate(
      props?.selectedUserDetail?.marylandLicenseDetails?.expirationDate
    );
    setMdVerificationLink(
      props?.selectedUserDetail?.marylandLicenseDetails?.verificationLink
    );
    setSupervisorEmail(props?.selectedUserDetail?.supervisor_email);
    setIsPsyPactMember(
      props?.selectedUserDetail?.psyPactDetails?.isPsyPactMember || ''
    );
    setPsyPactMobilityNumber(
      props?.selectedUserDetail?.psyPactDetails?.psyPactMobilityNumber || ''
    );
    setPsyPactVerification(
      props?.selectedUserDetail?.psyPactDetails?.psyPactVerification || ''
    );
    setMemberJoiningDate(
      props?.selectedUserDetail?.psyPactDetails?.originalDateReceived || ''
    );
    setMemberExpiryDate(
      props?.selectedUserDetail?.psyPactDetails?.expiryDate || ''
    );
    setWaitingOnMdLicense(
      props?.selectedUserDetail?.marylandLicenseDetails?.waitingOnMdLicense
    );
    setContactedMDBoard(
      props?.selectedUserDetail?.marylandLicenseDetails?.contactedMDBoard
    );
    setContactedPsyPact(
      props?.selectedUserDetail?.psyPactDetails?.contactedPsyPact || false
    );
    setNotRenewingPsyPact(
      props?.selectedUserDetail?.psyPactDetails?.notRenewingPsyPact || false
    );

    if (
      props.selectedUserDetail?.licensed_in_other_states &&
      props.selectedUserDetail.licensed_in_other_states
        .split(',')
        .filter(Boolean).length > 0
    ) {
      setOtherStatesLicensed('yes');
      setShowStatesDropdown(true);
    } else {
      setOtherStatesLicensed('no');
      setShowStatesDropdown(false);
    }

    setStateDetails({
      ...props.selectedUserDetail?.licenseInOtherStateDetails,
    });
  }, [props.selectedUserDetail]);

  // The below useEffect resets the Member Expiry Date if Dialog is opened
  useEffect(() => {
    if (openPsyPactModal) {
      setMemberExpiryDate('');
    }
  }, [openPsyPactModal, props.selectedUserDetail]);

  // The below useEffect resets the MaryLand Expiry Date if Dialog is opened
  useEffect(() => {
    if (openMDLicModal) {
      setMdExpirationDate('');
    }
  }, [openMDLicModal, props.selectedUserDetail]);

  useEffect(() => {
    async function getLiabilityRenewalOptions() {
      const currentLiabilityFile = liabilityFiles.find(
        (file) => file.type === activeLiabilityType
      );
      if (!currentLiabilityFile) return;

      try {
        const response = await liabilityService.getLiabilityRenewalOptions(
          currentLiabilityFile.file_id
        );
        setRenewalOptions((prev) => ({
          ...prev,
          [activeLiabilityType]: {
            policyPurchased:
              response?.data?.renewalOptions?.policyPurchased || false,
            policyAutoRenewal:
              response?.data?.renewalOptions?.policyAutoRenewal || false,
            contactedInsurance:
              response?.data?.renewalOptions?.contactedInsurance || false,
          },
        }));
      } catch (error) {
        console.error('Error fetching renewal options:', error);
        enqueueSnackbar('Failed to fetch renewal options.', {
          variant: 'error',
          timeout: 3000,
        });
      }
    }

    if (liabilityFiles.length > 0) {
      getLiabilityRenewalOptions();
    }
  }, [liabilityFiles, activeLiabilityType, enqueueSnackbar]);

  useEffect(() => {
    userService.getAllUsers().then(
      async (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return (
              user.roleId === 5 && // filtering provider from users
              user.id !== props.selectedUserDetail?.id
            );
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.

        setProvidersOptions(providers);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (degreesAndLicense.includes('Non-Fully Licensed')) {
      setShowSupervisorNameField(true);
      setShowSupervisorEmailField(true);
    } else {
      setShowSupervisorNameField(false);
      setShowSupervisorEmailField(false);
    }
    if (
      degreesAndLicense.includes('Fully Licensed') &&
      degreesAndLicense.includes('Doctoral Level')
    ) {
      setShowPsyPactMember(true);
    } else {
      setShowPsyPactMember(false);
    }
  }, [degreesAndLicense]);

  useEffect(() => {
    if (selectedUserDetail?.id) {
      activeAccordions.forEach((accordion) => {
        fetchData(accordion);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserDetail, activeAccordions]);

  useEffect(() => {
    if (
      Array.isArray(licensedInOtherStates) &&
      licensedInOtherStates.length > 0
    ) {
      const validStates = licensedInOtherStates.filter((state) => state); // Filter out falsy values like '', undefined, null

      const newDetails = { ...stateDetails };
      let hasChanges = false;
      validStates.forEach((state) => {
        if (!newDetails[state]) {
          hasChanges = true; // Flag that changes are needed
          newDetails[state] = {
            licenseNumber: '',
            effectiveDate: '',
            expirationDate: '',
            verificationLink: '',
            contactedMDBoard: false,
            noPlanToRenew: false,
            expirationDateEmpty: true, // Initialize the expirationDateEmpty flag as true
          };
        } else {
          // Update existing states with the validation flag if not already present
          if (newDetails[state].expirationDateEmpty === undefined) {
            newDetails[state].expirationDateEmpty =
              !newDetails[state].expirationDate;
          }
        }
      });
      if (hasChanges) {
        setStateDetails(newDetails);
      }
    }
  }, [licensedInOtherStates, stateDetails]); // Include stateDetails to track changes

  useEffect(() => {
    setExpirationMDDateEmpty(!mdExpirationDate);
  }, [mdExpirationDate]);

  // useEffect to monitor checkboxes for MD State License

  useEffect(() => {
    const mdLicenseDetails = props.selectedUserDetail?.marylandLicenseDetails;
    if (!mdLicenseDetails || !mdLicenseDetails.expirationDate) return; // Ensure mdLicenseDetails and expirationDate are available

    const today = new Date();
    const expirationDate = new Date(mdLicenseDetails.expirationDate);
    const fiftyDaysFromNow = addDays(today, 50);

    // Uncheck the "contacted MD board" checkbox if the expiration date is 50 days or more from today
    if (
      expirationDate >= fiftyDaysFromNow &&
      mdLicenseDetails.contactedMDBoard
    ) {
      setContactedMDBoard(false); // Assuming you have a state hook for this checkbox
    }
  }, [props.selectedUserDetail]); // Updated to watch the entire selectedUserDetail object

  useEffect(() => {
    const psyPactDetails = props.selectedUserDetail?.psyPactDetails;
    if (!psyPactDetails || !psyPactDetails.expiryDate) return; // Ensure psyPactDetails and expiryDate are available

    const today = new Date();
    const expirationDate = new Date(psyPactDetails.expiryDate);
    const fiftyDaysFromNow = addDays(today, 50);
    const dayAfterExpiration = addDays(expirationDate, 1);

    // Uncheck the renewal checkbox if the expiration date is at least 50 days from today
    if (
      expirationDate >= fiftyDaysFromNow &&
      props.selectedUserDetail?.psyPactDetails?.contactedPsyPact
    ) {
      setContactedPsyPact(false);
      // Optionally trigger a backend update to reflect this change
    }

    // Check if the current date is the day after the expiration date
    if (isEqual(dayAfterExpiration, startOfDay(today))) {
      // Additional conditions: no checkboxes are checked or the not renewing checkbox is checked
      const noRenewalActionsChecked =
        !props.selectedUserDetail?.psyPactDetails?.contactedPsyPact &&
        !props.selectedUserDetail?.psyPactDetails?.policyAutoRenewal &&
        !props.selectedUserDetail?.psyPactDetails?.policyPurchased;
      const notRenewingChecked =
        props.selectedUserDetail?.psyPactDetails?.notRenewingPsyPact;

      if (noRenewalActionsChecked || notRenewingChecked) {
        setIsPsyPactMember('no');
      }
    }
  }, [props.selectedUserDetail]); // Updated to watch the entire selectedUserDetail object

  const fetchData = (accordion) => {
    let folder;
    switch (accordion) {
      case 'cv':
        folder = 'cv';
        break;
      case 'diploma':
        folder = 'diploma';
        break;
      case 'liability':
        folder = 'liability';
        break;
      default:
        folder = '';
        break;
    }

    if (folder) {
      dispatch(setLoading());
      driveService
        .viewFiles({ folder, provider_id: props?.selectedUser?.id })
        .then((res) => {
          if (accordion === 'cv') {
            setCvFiles(res.data.data.files || []);
          } else if (accordion === 'diploma') {
            setDiplomaFiles(res.data.data.files || []);
          } else if (accordion === 'liability') {
            setLiabilityFiles(res.data.data.files || []);
          }
          dispatch(clearLoading());
        })
        .catch((error) => {
          console.error(`Error fetching files for ${accordion}:`, error);
          dispatch(clearLoading());
        });
    }
  };

  const handleAccordionChange = (accordion) => (event, isExpanded) => {
    setActiveAccordions((prev) => {
      // Creating a new Set for immutability
      const newActive = new Set(prev);
      if (isExpanded) {
        newActive.add(accordion);
      } else {
        newActive.delete(accordion);
      }
      return new Set(newActive); // Ensure a new object is returned to trigger re-render
    });
  };

  const handleDegreesAndLicenseChange = (event) => {
    const { value } = event.target;

    // Extract the new values from the event
    const newValues = typeof value === 'string' ? value.split(',') : value;

    // Check for conflicts
    const hasConflict =
      (newValues.includes('Fully Licensed') &&
        degreesAndLicense.includes('Non-Fully Licensed')) ||
      (newValues.includes('Non-Fully Licensed') &&
        degreesAndLicense.includes('Fully Licensed'));

    if (!hasConflict) {
      // If no conflict, update the state
      setDegreesAndLicense(newValues);
    } else {
      enqueueSnackbar(
        'Fully Licensed and Non-Fully Licensed cannot come together',
        {
          variant: 'error',
          timeout: 3000,
        }
      );
    }
  };

  const handlelicensedInOtherStatesChange = (event) => {
    const {
      target: { value },
    } = event;
    const filteredValue =
      typeof value === 'string'
        ? value.split(',').filter((state) => state)
        : value.filter((state) => state); // Filter out any empty strings
    setLicensedInOtherStates(filteredValue);
  };

  function handleChange(event, value) {
    setSelectedOption(value);

    // Check if a value was selected
    if (value) {
      // Extract the username and email from the selected option
      const supervisorName = value.username;
      const supervisorEmail = value.email;

      // Update the supervisorName and supervisorEmail states
      setSupervisorName(supervisorName);
      setSupervisorEmail(supervisorEmail);
    } else {
      // No value was selected, so reset the supervisorName and supervisorEmail states to empty strings
      setSupervisorName('');
      setSupervisorEmail('');
    }
  }

  const handleRadioOtherStateChange = (event) => {
    const selectedValue = event.target.value;
    setOtherStatesLicensed(selectedValue);
    setShowStatesDropdown(selectedValue === 'yes');
  };

  const handleDetailChange = (state, field) => (event) => {
    const newValue = event.target.value;
    setStateDetails((prevDetails) => ({
      ...prevDetails,
      [state]: {
        ...prevDetails[state],
        [field]: newValue,
        contactedMDBoard: false, // setting this to false
      },
    }));
    // setting changes made for Events
    setChangesMadeForEvents({
      ...changesMadeForEvents,
      [state]: true,
    });
  };

  const handleOnSubmit = async (event, openFrom) => {
    // Close all potentially open modals, only one should be open at any given time
    if (openMDLicModal) setOpenMDLicModal(false);
    if (openPsyPactModal) setOpenPsyPactModal(false);
    if (openActiveStateModal) setOpenActiveStateModal(false);

    try {
      event.preventDefault();
      if (props?.selectedUser?.id) {
        let dobUpdated = dob === 'Invalid date' ? '' : dob;
        if (
          props.selectedUserDetail?.provider_email &&
          dobUpdated &&
          providerName &&
          degreesAndLicense &&
          degreesAndLicense.length > 0
        ) {
          dispatch(setLoading()); // setting the loading here.

          await providerService.updateProviderDetails(props.selectedUser.id, {
            email: props.selectedUserDetail?.provider_email,
            inTesting: props.selectedUserDetail?.in_testing,
            inTherapy: props.selectedUserDetail?.in_therapy,
            supervisorEmail,
            supervisorName,
            degreesAndLicense,
            licensedInOtherStates:
              otherStatesLicensed === 'no' ? [] : licensedInOtherStates,
            providerName,
            marylandLicenseDetails: {
              waitingOnMdLicense: waitingOnMdLicense,
              licenseNumber: mdLicenseNumber,
              effectiveDate: mdEffectiveDate,
              expirationDate: mdExpirationDate,
              verificationLink: mdVerificationLink,
              // If it has been opened from renew
              contactedMDBoard: openFrom === 'renew' ? false : contactedMDBoard,
              contactAttemptCounter: contactAttemptCounter,
            },
            psyPactDetails:
              isPsyPactMember === 'yes'
                ? {
                    isPsyPactMember: isPsyPactMember,
                    psyPactMobilityNumber: psyPactMobilityNumber,
                    originalDateReceived: memberJoiningDate,
                    expiryDate: memberExpiryDate,
                    psyPactVerification: psyPactVerification,
                    contactedPsyPact:
                      openFrom === 'psypact' ? false : contactedPsyPact,
                    // If it has been opened from renew
                    notRenewingPsyPact: notRenewingPsyPact,
                  }
                : {
                    isPsyPactMember: isPsyPactMember, // only setting no
                  },
            licenseInOtherStateDetails:
              otherStatesLicensed === 'yes' // sending other state details
                ? {
                    ...stateDetails,
                  }
                : null,
            credentialing_tasks_created: getUpdatedCredentialingTaskCreated(
              props?.selectedUserDetail?.credentialing_tasks_created,
              changesMadeForEvents,
              props?.selectedUserDetail
            ),
            /*
            The below function is for getting updated License Email Cases
            Because if the provider has renewed Maryland, PsyPact, outOfState
            then their cases should be reset
            */
            licenseEmailCases: getUpdatedLicenseEmailCases(
              props?.selectedUserDetail?.licenseEmailCases,
              changesMadeForEvents
            ),
          });

          // Ensuring that It has licenseNumber and expirationDate and MaryLand events are changed
          if (
            mdLicenseNumber &&
            mdExpirationDate &&
            changesMadeForEvents.Maryland === true
          ) {
            // The below is the case for License Events
            await createOrUpdateLicenseEvent(
              props?.selectedUserDetail?.provider_name,
              mdExpirationDate,
              'Maryland'
            );
          }

          // The below case checks if it is psypact member and it has memberExpiryDate and PsyPact Change
          if (
            isPsyPactMember &&
            memberExpiryDate &&
            changesMadeForEvents.psyPactChange === true
          ) {
            await createOrUpdateLicenseEvent(
              props?.selectedUserDetail?.provider_name,
              memberExpiryDate,
              'PsyPact'
            );
          }

          // If it is not psyPactMember then immediately delete the event
          if (isPsyPactMember === 'no') {
            await getLicenseEventsAndDelete(
              props?.selectedUserDetail?.provider_name,
              'PsyPact'
            );
          }

          // If other state license is yes, It means we need to create event
          if (otherStatesLicensed === 'yes') {
            for (const state in stateDetails) {
              if (
                stateDetails.hasOwnProperty(state) &&
                stateDetails[state].expirationDate &&
                changesMadeForEvents[state]
              ) {
                await createOrUpdateLicenseEvent(
                  props?.selectedUserDetail?.provider_name,
                  stateDetails[state].expirationDate,
                  state
                );
              }
            }
          } else {
            for (const state in stateDetails) {
              if (
                stateDetails.hasOwnProperty(state) &&
                stateDetails[state].expirationDate
              ) {
                await getLicenseEventsAndDelete(
                  props?.selectedUserDetail?.provider_name,
                  state
                );
              }
            }
          }
          // The below clearing the loading
          dispatch(clearLoading());

          enqueueSnackbar('Updated Provider ', {
            variant: 'success',
            timeout: 3000,
          });

          props.updateProviderDetail();
          // Set the value of the Autochangescomplete component to the selected supervisor name
          setSupervisorName(selectedOption?.username || '');
          setSupervisorEmail(selectedOption?.supervisor_Email || '');
          // Below updating changesMadeForEvent
          setChangesMadeForEvents({});
        } else {
          enqueueSnackbar('Please select required fields.', {
            variant: 'error',
            timeout: 3000,
          });
        }
      } else {
        enqueueSnackbar('Please select provider first ', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.tabsContentContainer}>
        <Grid2 size={{ xs: 12 }}>
          <div style={{ marginBottom: 16 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: '#f0f0f0', // Light grey background
                  color: '#333', // Dark text for contrast
                  borderBottom: '1px solid #ccc', // Subtle border
                  '&:hover': {
                    backgroundColor: '#e9e9e9', // Darker on hover
                  },
                }}
              >
                <Typography>
                  Degrees & Licensure{' '}
                  {isAccordionDegreeLicenseCompleted && (
                    <>
                      <CheckCircleIcon
                        style={{
                          color: 'green',
                          marginLeft: '10px',
                          marginRight: '5px',
                        }}
                      />
                      {/* Displaying the summary next to the checkmark with added space */}
                      <span style={{ marginLeft: '2px' }}>
                        {degreesAndLicense.join(' & ')}
                      </span>
                    </>
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid2 size={{ xs: 12 }}>
                  <InputLabel id="demo-multiple-name-label">
                    Degrees And License
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={
                      Array.isArray(degreesAndLicense) ? degreesAndLicense : []
                    }
                    onChange={handleDegreesAndLicenseChange}
                    disabled={props.isProvider}
                    input={<OutlinedInput label="Degrees And License" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                    renderValue={(selected) => selected?.join(', ')}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'blue',
                        borderWidth: '1px',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'blue',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'blue',
                      },
                    }}
                  >
                    {degreesAndLicenseOptions.map((name, index) => (
                      <MenuItem key={name + index} value={name}>
                        <Checkbox
                          checked={degreesAndLicense.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid2>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid2>

        {showSupervisorNameField && (
          <Grid2 size={{ xs: 12 }}>
            <div style={{ marginBottom: 16 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: '#f0f0f0', // Light grey background
                    color: '#333', // Dark text for contrast
                    borderBottom: '1px solid #ccc', // Subtle border
                    '&:hover': {
                      backgroundColor: '#e9e9e9', // Darker on hover
                    },
                  }}
                >
                  <Typography>
                    Supervision{' '}
                    {isAccordionSupervisionCompleted && (
                      <>
                        <CheckCircleIcon
                          style={{
                            color: 'green',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ marginLeft: '2px' }}>
                          Supervisor: {supervisorName}
                        </span>
                      </>
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 6 }}>
                      <Autocomplete
                        disablePortal
                        id="supervisor_name"
                        label="Supervisor Name"
                        defaultValue={supervisorName}
                        value={supervisorName}
                        options={providersOptions}
                        getOptionLabel={(option) =>
                          option.username || supervisorName
                        }
                        onChange={handleChange}
                        disabled={props.isProvider}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            name="supervisor_"
                            label="Supervisor"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue',
                                  borderWidth: '1px',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'blue',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'blue',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid2>

                    {showSupervisorEmailField && (
                      <Grid2 size={{ xs: 6 }}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="outlined"
                          id="supervisor_Email"
                          label="Supervisor Email"
                          readOnly={props.isProvider} // Use readOnly instead of disabled if the field should not be editable
                          value={supervisorEmail}
                          // Remove onChange if the field is read-only
                          onChange={(e) => setSupervisorEmail(e.target.value)}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'blue',
                                borderWidth: '1px',
                              },
                              '&:hover fieldset': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </Grid2>
                    )}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid2>
        )}

        <Grid2 size={{ xs: 12 }} ref={maryLandStateLicenseRef}>
          <div style={{ marginBottom: 16 }}>
            <Accordion
              expanded={activeAccordions.has('MDLicense')}
              onChange={handleAccordionChange('MDLicense')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: '#f0f0f0', // Light grey background
                  color: '#333', // Dark text for contrast
                  borderBottom: '1px solid #ccc', // Subtle border
                  '&:hover': {
                    backgroundColor: '#e9e9e9', // Darker on hover
                  },
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {contactedMDBoard && (
                    <div
                      className={`${styles.priorityOrangeRibbon} ${
                        activeAccordions.has('MDLicense')
                          ? styles.expandedRibbon
                          : styles.collapsedRibbon
                      }`}
                    >
                      <span className={styles.highlightedText}>
                        Contacted MD Board
                      </span>{' '}
                    </div>
                  )}

                  <Typography>
                    Maryland State License{' '}
                    {isAccordionMdLicenseCompleted && (
                      <>
                        <CheckCircleIcon
                          style={{
                            color: 'green',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ marginLeft: '2px' }}>
                          {waitingOnMdLicense || !mdLicenseNumber ? (
                            'Licensure information pending'
                          ) : (
                            <>
                              MD License #: {mdLicenseNumber}, Original Date:{' '}
                              {mdEffectiveDate},{' '}
                              <span
                                style={{
                                  color:
                                    getExpirationDateColor(mdExpirationDate),
                                }}
                              >
                                Expiration Date: {mdExpirationDate}
                              </span>
                              ,
                              {mdVerificationLink && (
                                <a
                                  href={mdVerificationLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ marginLeft: '5px' }}
                                >
                                  Link
                                </a>
                              )}
                            </>
                          )}
                        </span>
                      </>
                    )}
                  </Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <Grid2 container spacing={3}>
                  {degreesAndLicense.includes('Non-Fully Licensed') &&
                    !mdLicenseNumber && (
                      <Grid2 size={{ xs: 4 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={waitingOnMdLicense}
                              onChange={(e) =>
                                setWaitingOnMdLicense(e.target.checked)
                              }
                            />
                          }
                          label="I'm still waiting on my licensure information"
                        />
                      </Grid2>
                    )}

                  {(degreesAndLicense.includes('Fully Licensed') ||
                    (degreesAndLicense.includes('Non-Fully Licensed') &&
                      !waitingOnMdLicense)) && (
                    <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Maryland State License Number"
                        value={mdLicenseNumber || ''}
                        onChange={(e) => setMdLicenseNumber(e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'blue',
                              borderWidth: '1px',
                            },
                            '&:hover fieldset': {
                              borderColor: 'blue',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'blue',
                            },
                          },
                        }}
                      />
                    </Grid2>
                  )}

                  {(degreesAndLicense.includes('Fully Licensed') ||
                    (mdLicenseNumber && !waitingOnMdLicense)) && (
                    <>
                      <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="date"
                          label="Original Effective Date"
                          value={mdEffectiveDate || ''}
                          onChange={(e) => setMdEffectiveDate(e.target.value)}
                          componentsProps={{
                            label: {
                              shrink: true,
                            },
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'blue',
                                borderWidth: '1px',
                              },
                              '&:hover fieldset': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="date"
                          label="Expiration Date"
                          value={mdExpirationDate || ''}
                          onChange={(e) => {
                            // The below setting the actual expirationDate
                            setMdExpirationDate(e.target.value);
                            setChangesMadeForEvents({
                              ...changesMadeForEvents,
                              Maryland: true,
                            });
                          }}
                          componentsProps={{
                            label: {
                              shrink: true,
                            },
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'blue',
                                borderWidth: '1px',
                              },
                              '&:hover fieldset': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <Typography>
                          Link to License Verification Page
                          <Tooltip
                            title="To learn more about how to verify your license, click here."
                            // classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton aria-label="help">
                              <HelpOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Link to Verification Page"
                          value={mdVerificationLink || ''}
                          onChange={(e) =>
                            setMdVerificationLink(e.target.value)
                          }
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'blue',
                                borderWidth: '1px',
                              },
                              '&:hover fieldset': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />

                        <Grid2
                          item
                          xs={12}
                          style={{ marginTop: '20px', marginBottom: '20px' }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setMdExpirationDate(''); // Reset expiration date
                              setOpenMDLicModal(true);
                            }}
                          >
                            Renew Maryland License
                          </Button>
                        </Grid2>

                        {shouldShowRenewalOptions(
                          mdExpirationDate,
                          'license'
                        ) && (
                          <div>
                            {mdLicenseNumber && !waitingOnMdLicense && (
                              <div
                                style={{
                                  marginTop: '32px',
                                  border: '1px solid #ccc',
                                  padding: '10px',
                                  borderRadius: '5px',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ marginBottom: '10px' }}
                                >
                                  Renewal Options
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={handleContactedMDBoardChange}
                                      checked={contactedMDBoard}
                                    />
                                  }
                                  label="I have already contacted the MD board"
                                  style={{
                                    display: 'block',
                                    marginBottom: '4px',
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Grid2>
                    </>
                  )}
                </Grid2>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid2>

        <Grid2 size={{ xs: 12 }} ref={psypactRef}>
          <div style={{ marginBottom: 16 }}>
            {showPsyPactMember && (
              <Accordion
                expanded={activeAccordions.has('PsyPact')}
                onChange={handleAccordionChange('PsyPact')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: '#f0f0f0', // Light grey background
                    color: '#333', // Dark text for contrast
                    borderBottom: '1px solid #ccc', // Subtle border
                    '&:hover': {
                      backgroundColor: '#e9e9e9', // Darker on hover
                    },
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {contactedPsyPact && (
                      <div
                        className={`${styles.priorityOrangeRibbon} ${
                          activeAccordions.has('PsyPact')
                            ? styles.expandedRibbon
                            : styles.collapsedRibbon
                        }`}
                      >
                        <span className={styles.highlightedText}>
                          Contacted PsyPact
                        </span>{' '}
                      </div>
                    )}
                    {notRenewingPsyPact && (
                      <div
                        className={`priorityOrangeRibbon ${
                          activeAccordions.has('PsyPact')
                            ? 'expandedRibbon'
                            : 'collapsedRibbon'
                        }`}
                      >
                        <span className={styles.highlightedText}>
                          Not Renewing PsyPact License
                        </span>{' '}
                      </div>
                    )}

                    <Typography>
                      PsyPact{' '}
                      {isAccordionPsyPactCompleted && (
                        <>
                          {isPsyPactMember === 'yes' ? (
                            <CheckCircleIcon
                              style={{
                                color: 'green',
                                marginLeft: '10px',
                                marginRight: '5px',
                              }}
                            />
                          ) : (
                            <CheckCircleOutlineIcon
                              style={{
                                color: 'green',
                                marginLeft: '10px',
                                marginRight: '5px',
                              }}
                            />
                          )}
                          <span style={{ marginLeft: '2px' }}>
                            {isPsyPactMember === 'no'
                              ? 'Not a member of PsyPact'
                              : `PsyPact Member: Mobility #: ${psyPactMobilityNumber}, Original Date: ${memberJoiningDate}, Expires: ${memberExpiryDate}, `}
                            {isPsyPactMember === 'yes' &&
                              psyPactVerification && (
                                <span style={{ marginLeft: '5px' }}>
                                  Attested
                                </span>
                              )}
                          </span>
                        </>
                      )}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 container spacing={3}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                      <InputLabel style={{ marginBottom: 8 }}>
                        Are You an Active PsyPact Member?
                      </InputLabel>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="psyPactMember"
                          name="psyPactMember"
                          value={isPsyPactMember}
                          onChange={(e) => setIsPsyPactMember(e.target.value)}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'blue',
                                borderWidth: '1px',
                              },
                              '&:hover fieldset': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid2>

                    {isPsyPactMember === 'yes' && (
                      <>
                        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="PsyPact Mobility Number"
                            value={psyPactMobilityNumber}
                            onChange={(e) =>
                              setPsyPactMobilityNumber(e.target.value)
                            }
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue',
                                  borderWidth: '1px',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'blue',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'blue',
                                },
                              },
                            }}
                          />
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                          <TextField
                            fullWidth
                            type="date"
                            label="Original Date Received"
                            variant="outlined"
                            value={memberJoiningDate}
                            onChange={(e) =>
                              setMemberJoiningDate(e.target.value)
                            }
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue',
                                  borderWidth: '1px',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'blue',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'blue',
                                },
                              },
                            }}
                            componentsProps={{
                              label: {
                                shrink: true,
                              },
                            }}
                          />
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                          <TextField
                            fullWidth
                            type="date"
                            label="Expires On"
                            variant="outlined"
                            value={memberExpiryDate}
                            onChange={(e) => {
                              // The below setting memeber expiry Date
                              setMemberExpiryDate(e.target.value);
                              // The below is for setting changesMade to make api call
                              setChangesMadeForEvents({
                                ...changesMadeForEvents,
                                psyPactChange: true,
                              });
                            }}
                            componentsProps={{
                              label: {
                                shrink: true,
                              },
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'blue',
                                  borderWidth: '1px',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'blue',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'blue',
                                },
                              },
                            }}
                          />
                        </Grid2>

                        <Grid2 size={{ xs: 12 }}>
                          <Typography>
                            I attest that the PsyPact expiration information
                            listed here is consistent with the expiration date
                            listed on PsyPact's verification page (
                            <a
                              href="https://www.verifypsypact.org/PsypactDirectory"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              click here for link
                            </a>
                            )
                            <Tooltip
                              title="To learn more about how to verify your license, click here."
                              // classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton aria-label="help">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Typography>

                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="psyPactVerification"
                              name="psyPactVerification"
                              value={psyPactVerification}
                              onChange={(e) =>
                                setPsyPactVerification(e.target.value)
                              }
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: 'blue',
                                    borderWidth: '1px',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'blue',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'blue',
                                  },
                                },
                              }}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid2>
                      </>
                    )}

                    <Grid2
                      item
                      xs={12}
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setMemberExpiryDate(''); // Reset expiration date
                          setOpenPsyPactModal(true);
                        }}
                      >
                        Renew PsyPact License
                      </Button>
                    </Grid2>

                    {/* This has been commented out will uncomment in next week*/}
                    {shouldShowRenewalOptions(memberExpiryDate, 'psypact') && (
                      <div>
                        {isPsyPactMember === 'yes' && (
                          <div
                            style={{
                              marginTop: '32px',
                              marginLeft: '32px',
                              marginBottom: '32px',
                              border: '1px solid #ccc',
                              padding: '10px',
                              borderRadius: '5px',
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{ marginBottom: '10px' }}
                            >
                              Renewal Options
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleContactedPsyPactChange}
                                  checked={contactedPsyPact}
                                />
                              }
                              label="I have already started the process to renew my PsyPact license"
                              style={{ display: 'block', marginBottom: '4px' }}
                            />
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              style={{ marginLeft: '32px' }}
                            >
                              This will snooze the next email alert.
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={notRenewingPsyPact}
                                  onChange={handleNotRenewingPsyPactChange}
                                />
                              }
                              label="I do not plan on renewing my PsyPact license"
                              style={{ display: 'block', marginBottom: '4px' }}
                            />
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              style={{ marginLeft: '32px' }}
                            >
                              This will end notifications.
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </Grid2>

        <Grid2 size={{ xs: 12 }} ref={outOfStateRef}>
          <div style={{ marginBottom: 16 }}>
            {degreesAndLicense.includes('Fully Licensed') && (
              <Accordion
                expanded={activeAccordions.has('outOfStateLicensure')}
                onChange={handleAccordionChange('outOfStateLicensure')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: '#f0f0f0', // Light grey background
                    color: '#333', // Dark text for contrast
                    borderBottom: '1px solid #ccc', // Subtle border
                    '&:hover': {
                      backgroundColor: '#e9e9e9', // Darker on hover
                    },
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      Out of State Licensure{' '}
                      {isAccordionOutStateLicenseCompleted && (
                        <>
                          {otherStatesLicensed === 'yes' ? (
                            <CheckCircleIcon
                              style={{
                                color: 'darkgreen',
                                marginLeft: '10px',
                                marginRight: '5px',
                              }}
                            />
                          ) : (
                            <CheckCircleOutlineIcon
                              style={{
                                color: 'green',
                                marginLeft: '10px',
                                marginRight: '5px',
                              }}
                            />
                          )}
                          <span style={{ marginLeft: '2px' }}>
                            {Object.entries(stateDetails)
                              .filter(
                                ([state, details]) =>
                                  details.licenseNumber !== '' &&
                                  details.effectiveDate !== '' &&
                                  details.expirationDate !== '' &&
                                  details.verificationLink !== ''
                              )
                              .sort(([a], [b]) => a.localeCompare(b))
                              .map(([state, details]) => (
                                <span
                                  key={state}
                                  style={{
                                    display: 'block',
                                    marginBottom: '5px',
                                  }}
                                >
                                  {`${state}: License #: ${details.licenseNumber}, Original Date: ${details.effectiveDate}, `}
                                  <span
                                    style={{
                                      color: getExpirationDateColor(
                                        details.expirationDate
                                      ),
                                    }}
                                  >
                                    {`Expiration Date: ${details.expirationDate}`}
                                  </span>
                                  {details.verificationLink && (
                                    <a
                                      href={details.verificationLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ marginLeft: '5px' }}
                                    >
                                      Verification Page
                                    </a>
                                  )}
                                </span>
                              ))}
                          </span>
                        </>
                      )}
                    </Typography>
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 4 }}>
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">{questionText}</FormLabel>
                        <RadioGroup
                          row
                          name="otherStatesLicensed"
                          value={otherStatesLicensed}
                          onChange={handleRadioOtherStateChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid2>

                    {showStatesDropdown && (
                      <>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <Typography variant="body1" sx={{ marginBottom: 1 }}>
                            Select All the States That You Are Licensed In
                            Outside of Maryland
                          </Typography>
                          <FormControl
                            fullWidth
                            sx={{
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              padding: '8px',
                            }}
                          >
                            <InputLabel id="licensed-in-other-states-label">
                              Licensed in Other States
                            </InputLabel>
                            <Select
                              labelId="licensed-in-other-states-label"
                              id="licensed-in-other-states-select"
                              multiple
                              value={licensedInOtherStates}
                              onChange={handlelicensedInOtherStatesChange}
                              input={<OutlinedInput label="Select States" />}
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={MenuProps}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: 'blue',
                                    borderWidth: '1px',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'blue',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'blue',
                                  },
                                },
                              }}
                            >
                              {licensedInOtherStatesOptions.map((state) => (
                                <MenuItem key={state} value={state}>
                                  <Checkbox
                                    checked={
                                      licensedInOtherStates.indexOf(state) > -1
                                    }
                                  />
                                  <ListItemText primary={state} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid2>
                        {licensedInOtherStates.length > 0 &&
                          licensedInOtherStates.map((state) => {
                            if (!stateDetails[state]) {
                              return null; // Do not render this state's details if it's not properly initialized
                            }

                            return (
                              <Grid2
                                item
                                xs={12}
                                key={state}
                                style={{
                                  border: '1px solid #ccc',
                                  padding: '10px',
                                  marginTop: '10px',
                                  borderRadius: '5px',
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                  }}
                                >{`License Details for ${state}`}</Typography>

                                <Grid2 container spacing={2}>
                                  <Grid2
                                    size={{
                                      xs: 12,
                                      sm: 12,
                                      md: 4,
                                      lg: 4,
                                      xl: 4,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      label="State License #"
                                      value={
                                        stateDetails[state].licenseNumber || ''
                                      }
                                      onChange={handleDetailChange(
                                        state,
                                        'licenseNumber'
                                      )}
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: 'blue',
                                            borderWidth: '1px',
                                          },
                                          '&:hover fieldset': {
                                            borderColor: 'blue',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: 'blue',
                                          },
                                        },
                                      }}
                                    />
                                  </Grid2>
                                  <Grid2
                                    size={{
                                      xs: 12,
                                      sm: 12,
                                      md: 4,
                                      lg: 4,
                                      xl: 4,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      type="date"
                                      label="Effective Date"
                                      value={
                                        stateDetails[state].effectiveDate || ''
                                      }
                                      onChange={handleDetailChange(
                                        state,
                                        'effectiveDate'
                                      )}
                                      componentsProps={{
                                        label: {
                                          shrink: true,
                                        },
                                      }}
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: 'blue',
                                            borderWidth: '1px',
                                          },
                                          '&:hover fieldset': {
                                            borderColor: 'blue',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: 'blue',
                                          },
                                        },
                                      }}
                                    />
                                  </Grid2>
                                  <Grid2
                                    size={{
                                      xs: 12,
                                      sm: 12,
                                      md: 4,
                                      lg: 4,
                                      xl: 4,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      type="date"
                                      label="Expiration Date"
                                      value={
                                        stateDetails[state].expirationDate || ''
                                      }
                                      onChange={handleDetailChange(
                                        state,
                                        'expirationDate'
                                      )}
                                      componentsProps={{
                                        label: {
                                          shrink: true,
                                        },
                                      }}
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: 'blue',
                                            borderWidth: '1px',
                                          },
                                          '&:hover fieldset': {
                                            borderColor: 'blue',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: 'blue',
                                          },
                                        },
                                      }}
                                    />
                                  </Grid2>
                                  <Grid2 size={{ xs: 12 }}>
                                    <Typography>
                                      Link to License Verification Page
                                      <Tooltip
                                        title="To learn more about how to verify your license, click here."
                                        // classes={{ tooltip: classes.tooltip }}
                                      >
                                        <IconButton aria-label="help">
                                          <HelpOutlineIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      label="Verification Link"
                                      value={
                                        stateDetails[state].verificationLink ||
                                        ''
                                      }
                                      onChange={handleDetailChange(
                                        state,
                                        'verificationLink'
                                      )}
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            borderColor: 'blue',
                                            borderWidth: '1px',
                                          },
                                          '&:hover fieldset': {
                                            borderColor: 'blue',
                                          },
                                          '&.Mui-focused fieldset': {
                                            borderColor: 'blue',
                                          },
                                        },
                                      }}
                                    />

                                    <Grid2
                                      item
                                      xs={12}
                                      style={{
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      <Button
                                        key={state}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          // Set the current active state
                                          setActiveState(state);
                                          // Reset the expiration date for this state
                                          setStateDetails((prevDetails) => ({
                                            ...prevDetails,
                                            [state]: {
                                              ...prevDetails[state],
                                              expirationDate: '',
                                            },
                                          }));
                                          // Reset the expiration date empty validation flag
                                          setExpirationActiveStateDateEmpty(
                                            true
                                          );
                                          // Open the modal
                                          setOpenActiveStateModal(true);
                                        }}
                                      >
                                        Renew {state} License
                                      </Button>
                                    </Grid2>

                                    {licensedInOtherStates.length > 0 &&
                                      stateDetails[state].expirationDate &&
                                      shouldShowRenewalOptions(
                                        stateDetails[state].expirationDate,
                                        'license'
                                      ) && (
                                        <div
                                          style={{
                                            marginTop: '32px',
                                            border: '1px solid #ccc',
                                            padding: '10px',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            style={{ marginBottom: '10px' }}
                                          >
                                            Renewal Options
                                          </Typography>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onChange={(e) =>
                                                  handleOutOfStateContactedBoard(
                                                    e,
                                                    state
                                                  )
                                                }
                                                checked={
                                                  stateDetails[state]
                                                    .contactedMDBoard
                                                }
                                              />
                                            }
                                            label="I have already contacted the board"
                                            style={{
                                              display: 'block',
                                              marginBottom: '4px',
                                            }}
                                          />
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            style={{ marginLeft: '32px' }}
                                          >
                                            This will snooze the next email
                                            alert.
                                          </Typography>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onChange={(e) =>
                                                  handleOutOfStateNoPlanToRenew(
                                                    e,
                                                    state
                                                  )
                                                }
                                                checked={
                                                  stateDetails[state]
                                                    .noPlanToRenew
                                                }
                                              />
                                            }
                                            label="I do not plan on renewing my license in this state"
                                            style={{
                                              display: 'block',
                                              marginBottom: '4px',
                                            }}
                                          />
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            style={{ marginLeft: '32px' }}
                                          >
                                            This will end notifications.
                                          </Typography>
                                        </div>
                                      )}
                                  </Grid2>
                                </Grid2>
                              </Grid2>
                            );
                          })}
                      </>
                    )}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </Grid2>
      </div>

      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 3 }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginBottom: '30px',
              marginLeft: '20px',
            }}
            onClick={handleOnSubmit}
            // disabled={props.isProvider}
          >
            Save
          </Button>
        </Grid2>
      </Grid2>

      <div style={sectionStyle}>
        <Typography variant="h6" style={{ marginBottom: '10px' }}>
          Documents Section
        </Typography>
        <div style={{ marginBottom: 16 }}>
          <Grid2 size={{ xs: 12 }}>
            <Accordion
              expanded={activeAccordions.has('cv')}
              onChange={handleAccordionChange('cv')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  color: '#333',
                  borderBottom: '1px solid #ccc',
                  '&:hover': {
                    backgroundColor: '#e9e9e9',
                  },
                }}
              >
                {' '}
                <Typography>
                  CV
                  {isAccordionCVCompleted ? (
                    <>
                      <CheckCircleIcon
                        style={{ color: 'green', marginLeft: '10px' }}
                      />
                      {cvFiles.length > 0 &&
                        cvFiles.map((file, index) => (
                          <span key={index}>
                            {` CV on File - Filename: (${
                              file.fileName
                            }) Uploaded On ${moment(file.createdAt).format(
                              'MM-DD-YYYY'
                            )} `}
                          </span>
                        ))}
                    </>
                  ) : (
                    <span> No CV Uploaded</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CvComponent
                  selectedUser={props.selectedUser}
                  selectedUserDetail={props.selectedUserDetail}
                  setTriggerAPICall={setTriggerAPICall}
                />
              </AccordionDetails>
            </Accordion>
          </Grid2>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Grid2 size={{ xs: 12 }}>
            <Accordion
              expanded={activeAccordions.has('diploma')}
              onChange={handleAccordionChange('diploma')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  color: '#333',
                  borderBottom: '1px solid #ccc',
                  '&:hover': {
                    backgroundColor: '#e9e9e9',
                  },
                }}
              >
                <Typography>
                  Diploma
                  {isAccordionDiplomaCompleted ? (
                    <>
                      <CheckCircleIcon
                        style={{ color: 'green', marginLeft: '10px' }}
                      />
                      {diplomaFiles.length > 0 &&
                        diplomaFiles.map((file, index) => (
                          <span key={index}>
                            {` Diploma on File (${
                              file.fileName
                            }) - Name of School: ${
                              file.graduating_school
                            } Graduated On ${moment(
                              file.graduating_date
                            ).format('MM-DD-YYYY')} `}
                          </span>
                        ))}
                    </>
                  ) : (
                    <span> No Diploma Uploaded</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DiplomaComponent
                  selectedUser={props.selectedUser}
                  selectedUserDetail={props.selectedUserDetail}
                  setTriggerAPICall={setTriggerAPICall}
                />
              </AccordionDetails>
            </Accordion>
          </Grid2>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Grid2 size={{ xs: 12 }}>
            <Accordion
              expanded={activeAccordions.has('liability')}
              onChange={handleAccordionChange('liability')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: '#f0f0f0',
                  color: '#333',
                  borderBottom: '1px solid #ccc',
                  '&:hover': {
                    backgroundColor: '#e9e9e9',
                  },
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {policyPurchased && (
                    <div
                      className={`priorityGreenRibbon ${
                        activeAccordions.has('liability')
                          ? 'expandedRibbon'
                          : 'collapsedRibbon'
                      }`}
                    >
                      <span className={styles.highlightedText}>
                        Practice Purchased Policy
                      </span>{' '}
                    </div>
                  )}
                  {policyAutoRenewal && (
                    <div
                      className={`${styles.priorityOrangeRibbon} ${
                        activeAccordions.has('liability')
                          ? styles.expandedRibbon
                          : styles.collapsedRibbon
                      }`}
                    >
                      <span className={styles.highlightedText}>
                        Auto Renewal Policy
                      </span>
                    </div>
                  )}
                  {contactedInsurance && (
                    <div
                      className={`${styles.priorityOrangeRibbon} ${
                        activeAccordions.has('liability')
                          ? styles.expandedRibbon
                          : styles.collapsedRibbon
                      }`}
                    >
                      <span className={styles.highlightedText}>
                        Recently Contacted Insurance
                      </span>
                    </div>
                  )}
                  <Typography>
                    Liability Insurance
                    {isAccordionLiabilityInsuranceCompleted && (
                      <>
                        <CheckCircleIcon
                          style={{ color: 'green', marginLeft: '10px' }}
                        />
                        {liabilityFiles
                          .filter((file) => file.type === 'current')
                          .map((file, index) => (
                            <span key={index}>
                              {` Liability Insurance On File - Name of File ${file.fileName} `}
                              <span
                                style={{
                                  color: getExpirationDateColor(
                                    file.expiry_date
                                  ),
                                }}
                              >
                                {`Expiration Date ${moment(
                                  file.expiry_date
                                ).format('MM-DD-YYYY')}`}
                              </span>
                            </span>
                          ))}
                      </>
                    )}
                    {!isAccordionLiabilityInsuranceCompleted && (
                      <span> No Current Liability Insurance</span>
                    )}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <LiabilityComponent
                  selectedUser={props.selectedUser}
                  selectedUserDetail={props.selectedUserDetail}
                  setTriggerAPICall={setTriggerAPICall}
                  accordionToOpen={props?.accordionToOpen}
                  activeLiabilityType={activeLiabilityType}
                  setActiveLiabilityType={setActiveLiabilityType}
                />

                {liabilityFiles.some(
                  (file) =>
                    file.type === 'current' &&
                    shouldShowRenewalOptions(file.expiry_date, 'liability')
                ) && (
                  <div
                    style={{
                      marginTop: '32px',
                      border: '1px solid #ccc',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="h6" style={{ marginBottom: '20px' }}>
                      Renewal Options
                    </Typography>
                    {hasCredentialingAccess(currentUser) && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                renewalOptions[activeLiabilityType]
                                  .policyPurchased
                              }
                              onChange={(e) =>
                                handlePolicyPurchasedChange(
                                  e,
                                  activeLiabilityType
                                )
                              }
                            />
                          }
                          label="Policy Purchased by Practice"
                          style={{ display: 'block', marginBottom: '4px' }}
                        />
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          style={{ marginLeft: '32px' }}
                        >
                          Only visible to Admin. If selected, reminder
                          notifications will be sent to practice instead of
                          provider.
                        </Typography>
                      </>
                    )}
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              renewalOptions[activeLiabilityType]
                                .policyAutoRenewal
                            }
                            onChange={(e) =>
                              handlePolicyAutoRenewalChange(
                                e,
                                activeLiabilityType
                              )
                            }
                          />
                        }
                        label="Policy is on auto-renewal"
                        style={{ display: 'block', marginBottom: '4px' }}
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ marginLeft: '32px' }}
                      >
                        Selecting this option will snooze your email reminder
                        notifications until 1 week prior to your expiration
                        date.
                      </Typography>
                    </>
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              renewalOptions[activeLiabilityType]
                                .contactedInsurance
                            }
                            onChange={(e) =>
                              handleContactedInsuranceChange(
                                e,
                                activeLiabilityType
                              )
                            }
                          />
                        }
                        label="Already contacted insurance and was told will take some time to get new policy document and information."
                        style={{ display: 'block', marginBottom: '4px' }}
                      />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ marginLeft: '32px' }}
                      >
                        Selecting this will snooze your next scheduled email
                        reminder.
                      </Typography>
                    </>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid2>
        </div>
      </div>

      {/* The below is Maryland Dialog */}
      <RenewMdDialog
        openMDLicModal={openMDLicModal}
        setOpenMDLicModal={setOpenMDLicModal}
        setChangesMadeForEvents={setChangesMadeForEvents}
        mdExpirationDate={mdExpirationDate}
        setMdExpirationDate={setMdExpirationDate}
        currentMDLicenseStep={currentMDLicenseStep}
        setCurrentMDLicenseStep={setCurrentMDLicenseStep}
        changesMadeForEvents={changesMadeForEvents}
        expirationMDDateEmpty={expirationMDDateEmpty}
        mdVerificationLink={mdVerificationLink}
        setLinkMDLicClicked={setLinkMDLicClicked}
        linkMDLicClicked={linkMDLicClicked}
        handleOnSubmit={handleOnSubmit}
      />

      {/* The below is OutOfState Dialog */}
      <OutOfStateDialog
        openActiveStateModal={openActiveStateModal}
        setOpenActiveStateModal={setOpenActiveStateModal}
        activeState={activeState}
        currentActiveStateStep={currentActiveStateStep}
        stateDetails={stateDetails}
        handleDetailChange={handleDetailChange}
        setChangesMadeForEvents={setChangesMadeForEvents}
        setCurrentActiveStateStep={setCurrentActiveStateStep}
        setLinkActiveStateClicked={setLinkActiveStateClicked}
        handleOnSubmit={handleOnSubmit}
        linkActiveStateClicked={linkActiveStateClicked}
      />

      {/* The below is PsyPact Dialog */}
      <PsyPactDialog
        currentPsyPactStep={currentPsyPactStep}
        openPsyPactModal={openPsyPactModal}
        setOpenPsyPactModal={setOpenPsyPactModal}
        memberExpiryDate={memberExpiryDate}
        setMemberExpiryDate={setMemberExpiryDate}
        expirationPsyPactDateEmpty={expirationPsyPactDateEmpty}
        setChangesMadeForEvents={setChangesMadeForEvents}
        changesMadeForEvents={changesMadeForEvents}
        handleOnSubmit={handleOnSubmit}
        linkPsyPactLicClicked={linkPsyPactLicClicked}
        setCurrentPsyPactStep={setCurrentPsyPactStep}
        setLinkPsyPactLicClicked={setLinkPsyPactLicClicked}
      />
    </React.Fragment>
  );
};

export default memo(ProviderProfessionalInfo);
