import React, { useState, useEffect, memo, useCallback } from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tabs, Tab, Button } from '@mui/material';
import { cptInsuranceService } from '../../services';
import styles from '../../styles/MyHandsOnTableComponent.module.css';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';

function MyHandsontableComponent() {
  const [changes, setChanges] = useState([]); // Store changes locally
  const { enqueueSnackbar } = useSnackbar(); // This hook provides the enqueueSnackbar method
  const [activeTab, setActiveTab] = useState(0); // 0 for Current, 1 for Archived
  const [currentData, setCurrentData] = useState([]);
  const [archivedData, setArchivedData] = useState([]);
  const [deletedRowIds, setDeletedRowIds] = useState([]);

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  /*
   The below useEffect adding event to 
   being copy and paste
  */
  useEffect(() => {
    const handleCopy = (event) => {
      console.log('Copy event triggered');
      const clipboardData = event.clipboardData || window.clipboardData;
      console.log('Clipboard items:', clipboardData.items);
      const data = clipboardData.getData('Text');
      console.log('Copying data:', data);
    };

    const handlePaste = (event) => {
      console.log('Paste event triggered');
      console.log('Pasting data:', event.clipboardData.getData('Text'));
    };
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    showLoading(); // Showing the loading here.
    cptInsuranceService
      .getAllBillingRates()
      .then((response) => {
        /*
         Saving data in current those that don't have endDate
         It means that this is current
        */
        const current = response.data
          .filter((rate) => !rate.end_date)
          .map((rate) => [
            rate.id,
            rate.cpt_code, // Start with cpt_code, omitting the ID
            rate.service_group,
            rate.service_type,
            rate.service_type_subtype,
            rate.insurance,
            rate.setting_location_dependent,
            rate.office_location,
            rate.masters_amount ? rate.masters_amount.toString() : '',
            rate.doctoral_amount ? rate.doctoral_amount.toString() : '',
            rate.time_bound,
            rate.min_time ? rate.min_time.toString() : '',
            rate.max_time ? rate.max_time.toString() : '',
            rate.amount_range,
            rate.masters_low_range ? rate.masters_low_range.toString() : '',
            rate.masters_high_range ? rate.masters_high_range.toString() : '',
            rate.doctoral_low_range ? rate.doctoral_low_range.toString() : '',
            rate.doctoral_high_range ? rate.doctoral_high_range.toString() : '',
            rate.notes ? rate.notes.toString() : '',
            rate.effective_date,
            rate.end_date,
            rate.updatedAt ? moment(rate.updatedAt).format('YYYY-MM-DD') : '', // Format updatedAt
          ]);

        /*
         Saving data in Archived those because that have
         end_date
        */
        const archived = response.data
          .filter((rate) => rate.end_date)
          .map((rate) => [
            rate.id,
            rate.cpt_code, // Start with cpt_code, omitting the ID
            rate.service_group,
            rate.service_type,
            rate.service_type_subtype,
            rate.insurance,
            rate.setting_location_dependent,
            rate.office_location,
            rate.masters_amount ? rate.masters_amount.toString() : '',
            rate.doctoral_amount ? rate.doctoral_amount.toString() : '',
            rate.time_bound,
            rate.min_time ? rate.min_time.toString() : '',
            rate.max_time ? rate.max_time.toString() : '',
            rate.amount_range,
            rate.masters_low_range ? rate.masters_low_range.toString() : '',
            rate.masters_high_range ? rate.masters_high_range.toString() : '',
            rate.doctoral_low_range ? rate.doctoral_low_range.toString() : '',
            rate.doctoral_high_range ? rate.doctoral_high_range.toString() : '',
            rate.notes ? rate.notes.toString() : '',
            rate.effective_date,
            rate.end_date,
            rate.updatedAt ? moment(rate.updatedAt).format('YYYY-MM-DD') : '', // Format updatedAt
          ]);

        setCurrentData(current);
        setArchivedData(archived);
        hideLoading(); // hiding the loading
      })
      .catch((error) => {
        console.log('error.message', error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAfterChange = (change, source) => {
    if (source !== 'loadData' && change) {
      setChanges((currentChanges) => [...currentChanges, ...change]);
    }
  };

  // Handling save upon the click of button
  const handleSave = async () => {
    try {
      showLoading(); // Show loading indicator

      let successfulUpdates = 0;
      let successfulCreations = 0;
      let skippedEntries = 0;
      let errors = 0;
      let successfulDeletions = 0; // Track successful deletions

      // Use the correct dataset based on the active tab
      const activeData = activeTab === 0 ? currentData : archivedData;

      // Create a set of row indices that were edited
      const editedRowIndices = new Set(changes.map((change) => change[0]));

      // Process edited rows
      for (const index of editedRowIndices) {
        const newEntry = activeData[index];
        // Validate the first few columns to ensure it's a valid entry
        const isValidNewEntry = newEntry
          .slice(1, 4) // Now cpt_code is at index 1, service_group at 2, service_type at 3
          .every((val) => val !== null && val !== '');

        if (!isValidNewEntry) {
          console.log('Skipping creation of new entry due to incomplete data.');
          skippedEntries++;
          continue;
        }

        // Extract the ID and other fields based on new indexing
        const rateId = newEntry[0]; // ID is first column
        const updated_date = moment(newEntry[21]).isValid()
          ? moment(newEntry[21]).format('YYYY-MM-DD')
          : null;

        /* 
        Making newRateData for saving it back in the database
        */
        const newRateData = {
          cpt_code: newEntry[1],
          service_group: newEntry[2],
          service_type: newEntry[3],
          service_type_subtype: newEntry[4],
          insurance: newEntry[5],
          setting_location_dependent: newEntry[6],
          office_location: newEntry[7],
          masters_amount: newEntry[8] ? parseFloat(newEntry[8]) : null,
          doctoral_amount: newEntry[9] ? parseFloat(newEntry[9]) : null,
          time_bound: newEntry[10],
          min_time: newEntry[11] ? parseInt(newEntry[11], 10) : null,
          max_time: newEntry[12] ? parseInt(newEntry[12], 10) : null,
          amount_range: newEntry[13],
          masters_low_range: newEntry[14] ? parseFloat(newEntry[14]) : null,
          masters_high_range: newEntry[15] ? parseFloat(newEntry[15]) : null,
          doctoral_low_range: newEntry[16] ? parseFloat(newEntry[16]) : null,
          doctoral_high_range: newEntry[17] ? parseFloat(newEntry[17]) : null,
          notes: newEntry[18] ? newEntry[18] : null,
          effective_date: newEntry[19]
            ? moment(newEntry[19]).format('YYYY-MM-DD')
            : null,
          end_date: newEntry[20]
            ? moment(newEntry[20]).format('YYYY-MM-DD')
            : null,
          updated_date: updated_date,
        };

        if (rateId) {
          // Existing record, update it
          try {
            await cptInsuranceService.updateBillingRate(rateId, newRateData);
            console.log('Update successful');
            successfulUpdates++;
          } catch (error) {
            console.error('Error updating the billing rate:', error);
            errors++;
          }
        } else {
          // No ID means this is a new record
          try {
            await cptInsuranceService.createBillingRate(newRateData);
            console.log('Creation successful');
            successfulCreations++;
          } catch (error) {
            console.error('Error creating a new billing rate:', error);
            errors++;
          }
        }
      }

      // Process deleted rows
      // Assuming you have a deletedRowIds state array that gets populated by afterRemoveRow
      if (deletedRowIds && deletedRowIds.length > 0) {
        for (const rowId of deletedRowIds) {
          try {
            await cptInsuranceService.deleteBillingRate(rowId);
            console.log(`Deleted rate with id ${rowId}`);
            successfulDeletions++;
          } catch (error) {
            console.error(`Error deleting rate with id ${rowId}:`, error);
            errors++;
          }
        }
        // Clear the deleted rows array after processing
        setDeletedRowIds([]);
      }

      // After all operations, re-fetch data to ensure UI is up-to-date
      const response = await cptInsuranceService.getAllBillingRates();

      /**
       * After deleting, getting the current rows (Current Rows are those rows that
       * don't have the end date
       * )
       */
      const updatedCurrent = response.data
        .filter((rate) => !rate.end_date)
        .map((rate) => [
          rate.id,
          rate.cpt_code,
          rate.service_group,
          rate.service_type,
          rate.service_type_subtype,
          rate.insurance,
          rate.setting_location_dependent,
          rate.office_location,
          rate.masters_amount ? rate.masters_amount.toString() : '',
          rate.doctoral_amount ? rate.doctoral_amount.toString() : '',
          rate.time_bound,
          rate.min_time ? rate.min_time.toString() : '',
          rate.max_time ? rate.max_time.toString() : '',
          rate.amount_range,
          rate.masters_low_range ? rate.masters_low_range.toString() : '',
          rate.masters_high_range ? rate.masters_high_range.toString() : '',
          rate.doctoral_low_range ? rate.doctoral_low_range.toString() : '',
          rate.doctoral_high_range ? rate.doctoral_high_range.toString() : '',
          rate.notes ? rate.notes.toString() : '',
          rate.effective_date,
          rate.end_date,
          rate.updated_date,
        ]);

      /**
       * After deleting, (archived rows are those rows that have the end date)
       * )
       */
      const updatedArchived = response.data
        .filter((rate) => rate.end_date)
        .map((rate) => [
          rate.id,
          rate.cpt_code,
          rate.service_group,
          rate.service_type,
          rate.service_type_subtype,
          rate.insurance,
          rate.setting_location_dependent,
          rate.office_location,
          rate.masters_amount ? rate.masters_amount.toString() : '',
          rate.doctoral_amount ? rate.doctoral_amount.toString() : '',
          rate.time_bound,
          rate.min_time ? rate.min_time.toString() : '',
          rate.max_time ? rate.max_time.toString() : '',
          rate.amount_range,
          rate.masters_low_range ? rate.masters_low_range.toString() : '',
          rate.masters_high_range ? rate.masters_high_range.toString() : '',
          rate.doctoral_low_range ? rate.doctoral_low_range.toString() : '',
          rate.doctoral_high_range ? rate.doctoral_high_range.toString() : '',
          rate.notes ? rate.notes.toString() : '',
          rate.effective_date,
          rate.end_date,
          rate.updated_date,
        ]);

      setCurrentData(updatedCurrent);
      setArchivedData(updatedArchived);

      // Construct a message based on the results
      if (errors > 0) {
        enqueueSnackbar(`There were errors processing some entries.`, {
          variant: 'error',
          timeout: 3000,
        });
      } else if (
        successfulUpdates > 0 ||
        successfulCreations > 0 ||
        successfulDeletions > 0
      ) {
        let message = `Processed ${successfulUpdates} updates, ${successfulCreations} creations`;
        if (successfulDeletions > 0) {
          message += `, ${successfulDeletions} deletions`;
        }
        message += ' successfully.';
        if (skippedEntries > 0) {
          message += ` Skipped ${skippedEntries} incomplete entries.`;
        }
        enqueueSnackbar(message, { variant: 'success', timeout: 3000 });
      } else if (skippedEntries > 0) {
        enqueueSnackbar(
          `Skipped ${skippedEntries} incomplete entries. No changes were made.`,
          { variant: 'info', timeout: 3000 }
        );
      } else {
        enqueueSnackbar('No changes were made.', {
          variant: 'info',
          timeout: 3000,
        });
      }

      setChanges([]); // Reset changes after save
    } catch (error) {
      console.log('Error in handleSave:', error.message);
    } finally {
      hideLoading();
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="billing rate tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          borderBottom: '2px solid #E0E0E0',
          background: '#F5F5F5',
          marginBottom: '20px',
        }}
      >
        <Tab
          label="Current"
          sx={{
            color: 'black',
            '&.Mui-selected': {
              color: 'black', // Ensures the selected tab is also black
            },
          }}
        />
        <Tab
          label="Archived"
          sx={{
            color: 'black',
            '&.Mui-selected': {
              color: 'black', // Ensures the selected tab is also black
            },
          }}
        />
      </Tabs>

      {activeTab === 0 && (
        <HotTable
          data={currentData}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          colHeaders={[
            'ID',
            'CPT Code',
            'Service Group',
            'Service Type',
            'Service Type Subtype',
            'Insurance',
            'Setting/Location Dependent',
            'Location',
            'Master’s Amount',
            'Doctoral Amount',
            'Time Bound',
            'Min Time',
            'Max Time',
            'Amount Range',
            'Master’s Low Range',
            'Master’s High Range',
            'Doctoral Low Range',
            'Doctoral High Range',
            'Notes',
            'Effective Date',
            'End Date',
            'Updated Date',
          ]}
          hiddenColumns={{
            columns: [0], // Hide the first column which is ID
            indicators: false,
          }}
          rowHeaders={true}
          width="1300"
          height="600"
          colWidths={150}
          afterChange={handleAfterChange}
          minSpareRows={1}
          wordWrap={true}
          filters={true}
          dropdownMenu={true}
          columnSorting={true}
          manualRowResize={true}
          manualColumnResize={true}
          contextMenu={{
            items: {
              row_above: {},
              row_below: {},
              col_left: {},
              col_right: {},
              remove_row: {},
              remove_col: {},
              undo: {},
              redo: {},
              make_read_only: {},
              alignment: {},
              '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
              copy: {},
              cut: {},
              paste: {
                name: 'Paste',
                disabled: function () {
                  // You can include conditions to disable or enable this option dynamically
                  return false;
                },
              },
            },
          }}
          copyPaste={true}
          cells={(row, col, prop) => {
            const cellProperties = {};
            cellProperties.className = styles.cellCentered;
            return cellProperties;
          }}
          beforeRemoveRow={(index, amount) => {
            console.log(
              'Before remove row at index:',
              index,
              'Amount:',
              amount
            );
            const removedRow = currentData[index]; // Use currentData here for the current tab
            console.log('Row to be removed:', removedRow);

            const removedId = removedRow && removedRow[0];
            if (removedId) {
              setDeletedRowIds((prev) => [...prev, removedId]);
            }
          }}
        />
      )}

      {activeTab === 1 && (
        <HotTable
          data={archivedData}
          licenseKey="non-commercial-and-evaluation"
          colHeaders={[
            'ID',
            'CPT Code',
            'Service Group',
            'Service Type',
            'Service Type Subtype',
            'Insurance',
            'Setting/Location Dependent',
            'Location',
            'Master’s Amount',
            'Doctoral Amount',
            'Time Bound',
            'Min Time',
            'Max Time',
            'Amount Range',
            'Master’s Low Range',
            'Master’s High Range',
            'Doctoral Low Range',
            'Doctoral High Range',
            'Notes',
            'Effective Date',
            'End Date',
            'Updated Date',
          ]}
          hiddenColumns={{
            columns: [0], // Hide the first column which is ID
            indicators: false,
          }}
          rowHeaders={true}
          width="1300"
          height="600"
          colWidths={150}
          afterChange={handleAfterChange}
          minSpareRows={1}
          wordWrap={true}
          columnSorting={true}
          manualRowResize={true}
          manualColumnResize={true}
          filters={true}
          dropdownMenu={true}
          copyPaste={true}
          contextMenu={{
            items: {
              row_above: {},
              row_below: {},
              col_left: {},
              col_right: {},
              remove_row: {},
              remove_col: {},
              undo: {},
              redo: {},
              make_read_only: {},
              alignment: {},
              '---------': Handsontable.plugins.ContextMenu.SEPARATOR,
              copy: {},
              cut: {},
              paste: {
                name: 'Paste',
                disabled: function () {
                  // You can include conditions to disable or enable this option dynamically
                  return false;
                },
              },
            },
          }}
          cells={(row, col, prop) => {
            const cellProperties = {};
            cellProperties.className = styles.cellCentered;
            return cellProperties;
          }}
          beforeRemoveRow={(index, amount) => {
            console.log(
              'Before remove row at index:',
              index,
              'Amount:',
              amount
            );
            const removedRow = archivedData[index]; // Use archivedData here for the archived tab
            console.log('Row to be removed:', removedRow);

            const removedId = removedRow && removedRow[0];
            if (removedId) {
              setDeletedRowIds((prev) => [...prev, removedId]);
            }
          }}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ marginTop: '20px' }} // Add margin directly with sx to ensure it applies
      >
        Save Changes
      </Button>
    </div>
  );
}

export default memo(MyHandsontableComponent);
