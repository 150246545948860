import React, { useState, useEffect, memo, useCallback } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { isDoctor, hasSuperAdminAccess } from '../../common/utils/RoleUtils';
// Below coming from Utility file
import { formatTime } from '../../common/utility';
import {
  userService,
  adminService,
  careCoordinatorService,
  providerService,
} from '../../services';

const DirectoryTable = () => {
  const dispatch = useDispatch();

  const [directoryEntries, setDirectoryEntries] = useState([]);
  const [showActive, setShowActive] = useState(true);
  const [filter, setFilter] = useState('All'); // Filter state

  // Get the currentUser from Redux
  const { user: currentUser } = useSelector((state) => state.auth);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    const fetchDirectoryData = async () => {
      try {
        // Showing the loader in the below
        showLoading();

        const status = showActive ? 'active' : 'inactive';
        const isActive = showActive; // true if active, false otherwise

        // Fetch providers, admins, and care coordinators in parallel
        const [
          providersResponse,
          adminsResponse,
          careCoordinatorsResponse,
          usersResponse,
        ] = await Promise.all([
          providerService.getProviders(status),
          adminService.getAdmins(status),
          careCoordinatorService.getCareCoordinators(status),
          userService.getAllUsers(),
        ]);

        const users = usersResponse.data.users;

        // ---------------------------
        // Providers (no title or schedule)
        // ---------------------------
        const providersWithImages = providersResponse.data.providers
          .filter((provider) => provider.provider_name != null)
          .map((provider) => {
            const user = users.find((u) => u.id === provider.id);
            return {
              ...provider,
              name: provider.provider_name || 'No Name',
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
              type: 'Provider',
              isActive: isActive,
              provider_email: provider.provider_email,
            };
          });

        // ---------------------------
        // Admins (with title & schedule)
        // ---------------------------
        const adminsWithImages = adminsResponse.data.map((admin) => {
          const user = users.find((u) => u.id === admin.id);
          return {
            ...admin,
            name: admin.admin_name || 'No Name',
            imageUrl:
              user?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            type: 'Admin',
            isActive: isActive,
            admin_email: admin.admin_email,
            // Additional fields for Admin
            title: admin.title,
            schedule: admin.schedule,
          };
        });

        // ---------------------------
        // Care Coordinators (with title & schedule)
        // ---------------------------
        const careCoordinatorsWithImages = careCoordinatorsResponse.data.map(
          (careCoordinator) => {
            const user = users.find((u) => u.id === careCoordinator.id);
            return {
              ...careCoordinator,
              name: careCoordinator.care_coordinator_name || 'No Name',
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
              type: 'Care Coordinator',
              isActive: isActive,
              care_coordinator_email: careCoordinator.care_coordinator_email,
              // Additional fields for Care Coordinator
              title: careCoordinator.title,
              schedule: careCoordinator.schedule,
            };
          }
        );

        // Combine and sort them
        const combinedEntries = [
          ...providersWithImages,
          ...adminsWithImages,
          ...careCoordinatorsWithImages,
        ].sort((a, b) => {
          const nameA = (a.name || '').toLowerCase();
          const nameB = (b.name || '').toLowerCase();
          return nameA.localeCompare(nameB);
        });

        setDirectoryEntries(combinedEntries);
      } catch (error) {
        console.error('Failed to fetch directory data:', error);
      } finally {
        hideLoading();
        // Hiding the loading above
      }
    };

    fetchDirectoryData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActive]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Filter the combined entries based on the selected filter
  const filteredEntries = directoryEntries.filter((entry) => {
    if (filter === 'All') return true;
    return entry.type === filter;
  });

  return (
    <div>
      {/**
       * The below are the filters whether it's provider, Admins or CareCoordinators
       */}
      <div
        style={{
          display: 'flex',
          justifyContent: isSmallScreen ? 'center' : 'flex-start',
        }}
      >
        <FormControl
          variant="outlined"
          style={{
            marginBottom: '1rem',
            minWidth: 200,
            marginTop: isSmallScreen
              ? isDoctor(currentUser)
                ? '20px'
                : '60px'
              : '20px',
          }}
        >
          <InputLabel id="filter-label">Filter</InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={filter}
            onChange={handleFilterChange}
            label="Filter"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Provider">Providers</MenuItem>
            <MenuItem value="Admin">Admins</MenuItem>
            <MenuItem value="Care Coordinator">Care Coordinators</MenuItem>
          </Select>
        </FormControl>
      </div>

      {currentUser?.roles.includes('ROLE_DOCTOR') ? (
        <Typography variant="h6"></Typography>
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={showActive}
              onChange={() => setShowActive(!showActive)}
              name="activeInactiveSwitch"
            />
          }
          label={showActive ? 'Active' : 'Inactive'}
        />
      )}

      {/** The below grid displays all filtered entries */}
      <Grid container spacing={2} sx={{ paddingLeft: '1.3rem' }}>
        {filteredEntries.map((entry, index) => {
          // Determine which email field to display
          const email =
            entry.provider_email ||
            entry.admin_email ||
            entry.care_coordinator_email ||
            '';

          // We'll only show Title & Schedule if the entry is Admin or Care Coordinator
          const isAdminOrCC =
            entry.type === 'Admin' || entry.type === 'Care Coordinator';

          return (
            <Grid size={{ xs: 12, sm: 2, md: 2 }} key={index}>
              <Card
                style={{
                  minHeight: '400px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardMedia
                  component="img"
                  height="100"
                  image={entry.imageUrl}
                  onError={(e) => {
                    e.target.src =
                      'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg';
                  }}
                  alt="User Photo"
                />
                <div
                  style={{
                    flex: 1,
                    overflow: 'auto',
                  }}
                >
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {/** --------------------
                      NAME
                     -------------------- */}
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      component="div"
                      align="center"
                      style={{
                        fontWeight: 'bold',
                        fontSize:
                          entry.name.length > 22
                            ? '0.8rem'
                            : entry.name.length > 20
                            ? '1.0rem'
                            : '1.1rem',
                      }}
                    >
                      {entry.name}
                    </Typography>

                    {/** --------------------
                      EXT & EMAIL (for Active entries only)
                     -------------------- */}
                    {entry.isActive && (
                      <>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: 'left', width: '100%' }}
                        >
                          <strong>Ext:</strong> {entry.ext}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          <strong>Email:</strong> {email}
                        </Typography>
                      </>
                    )}

                    {/** --------------------
                      TITLE (Admins & CC only)
                     -------------------- */}
                    {isAdminOrCC && entry.title && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ textAlign: 'left', width: '100%' }}
                      >
                        <strong>Title:</strong> {entry.title}
                      </Typography>
                    )}

                    {/** --------------------
                      SCHEDULE (Admins & CC only)
                      - Display parsed data in a nice format.
                     -------------------- */}
                    {isAdminOrCC &&
                      entry.schedule &&
                      entry.schedule.length > 0 && (
                        <Accordion style={{ marginTop: '8px', width: '100%' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`schedule-panel-${index}-content`}
                            id={`schedule-panel-${index}-header`}
                          >
                            <Typography style={{ fontWeight: 'bold' }}>
                              Schedule
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List disablePadding>
                              {entry.schedule.map((item, i) => (
                                <ListItem
                                  key={i}
                                  alignItems="flex-start"
                                  style={{ display: 'block', paddingLeft: 0 }}
                                >
                                  <ListItemText
                                    primary={`Days: ${item.days.join(', ')}`}
                                    secondary={
                                      <>
                                        <Typography variant="body2">
                                          <strong>Start:</strong>{' '}
                                          {formatTime(item.startTime)}
                                        </Typography>
                                        <Typography variant="body2">
                                          <strong>End:</strong>{' '}
                                          {formatTime(item.endTime)}
                                        </Typography>
                                        <Typography variant="body2">
                                          <strong>Setting:</strong>{' '}
                                          {item.setting || 'N/A'}
                                        </Typography>
                                      </>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      )}

                    {/** --------------------
                      PHONE & ADDRESS
                      (only visible to Super Admin)
                     -------------------- */}
                    {hasSuperAdminAccess(currentUser) && (
                      <>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: 'left', width: '100%' }}
                        >
                          <strong>Mobile:</strong>{' '}
                          {entry.phone || entry.mobile_number}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: 'left', width: '100%' }}
                        >
                          <strong>Address:</strong> {entry.address1}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: 'left', width: '100%' }}
                        >
                          {entry.city}, {entry.state} {entry.zip}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </div>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default memo(DirectoryTable);
