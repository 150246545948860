import React, { useState, useEffect, useMemo } from 'react';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { hasSuperAdminAccess } from '../../common/utils/RoleUtils';
import { userService, adminService } from '../../services';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';

function compareAdminFullName(a, b) {
  const lastNameA = a.admin_name ? a.admin_name.split(' ')[1] || '' : '';
  const lastNameB = b.admin_name ? b.admin_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

export default function AdminGeneralTable({ showActiveAdmins }) {
  const { enqueueSnackbar } = useSnackbar();
  const [locations, setLocations] = useState([]);
  const [locationSet, setLocationSet] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const status = showActiveAdmins ? 'active' : 'inactive';

    adminService
      .getAdmins(status)
      .then((response) => {
        const fetchedAdmins =
          response.data.filter((admin) => admin.admin_name != null) ?? [];

        return userService.getAllUsers().then((usersResponse) => {
          const users = usersResponse.data.users ?? [];

          const adminsWithImages = fetchedAdmins.map((admin) => {
            const user = users.find((user) => user.id === admin.id);
            return {
              ...admin,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          setLocations(adminsWithImages);
          setLocationSet(true);
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      });
  }, [showActiveAdmins, enqueueSnackbar]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedAdmins = useMemo(() => {
    return locations
      .sort(compareAdminFullName)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [locations, page, rowsPerPage]);

  const formattedAdmins = useMemo(() => {
    return displayedAdmins.map((admin) => {
      const dob = admin.date_of_birth
        ? moment(admin.date_of_birth).format('M/D/YY')
        : '';
      const age = admin.date_of_birth
        ? moment().diff(moment(admin.date_of_birth), 'years')
        : '';
      const dateOfBirthWithAge = `${dob}${age ? `\n(${age} yrs)` : ''}`;

      const homeAddress =
        `${admin.address1 || ''} ${admin.address2 || ''}`.trim() +
        `\n${admin.city || ''}, ${admin.state || ''} ${admin.zip || ''}`.trim();

      return {
        ...admin,
        date_of_birth: dateOfBirthWithAge,
        address: homeAddress,
      };
    });
  }, [displayedAdmins]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'admin_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
          width: '140px',
        },
      },
      {
        field: 'imageUrl',
        headerName: '',
        type: 'image',
        headerStyle: {
          width: '150px',
          minWidth: '150px',
          borderBottom: '0.5px solid black',
        },
        cellStyle: {
          width: '150px',
          minWidth: '150px',
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'admin_email',
        headerName: 'PsychCare Email',
        headerStyle: {
          width: '230px',
          minWidth: '230px',
          fontWeight: '900',
          borderBottom: '0.5px solid black',
        },
        cellStyle: {
          width: '230px',
          minWidth: '230px',
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'ext',
        headerName: 'Ext',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'mobile_number',
        headerName: 'Mobile #',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'date_of_birth',
        headerName: 'Date of Birth (Age)',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'address',
        headerName: 'Home Address',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
    ];

    if (hasSuperAdminAccess(currentUser)) {
      baseColumns.splice(3, 0, {
        field: 'personal_email',
        headerName: 'Personal Email',
        headerStyle: {
          width: '230px',
          minWidth: '230px',
          fontWeight: '900',
          borderBottom: '0.5px solid black',
        },
        cellStyle: {
          width: '230px',
          minWidth: '230px',
          borderBottom: '0.1px solid black',
        },
      });
      baseColumns.push({
        field: 'ss_num',
        headerName: 'SS#',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      });
    }

    return baseColumns;
  }, [currentUser]);

  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    fontSize: '1.0rem', // Increase font size
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };
  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      {locationSet && (
        <>
          <ResponsiveTableCard
            columns={columns}
            data={formattedAdmins}
            isDesktopTableStyled={true}
            tableHeaderCellStyle={tableHeaderCellStyle}
            tableCellStyle={tableCellStyle}
          />
          <TablePagination
            component="div"
            count={locations.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page:"
          />
        </>
      )}
    </div>
  );
}
